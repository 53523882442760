import React, { useEffect, useState } from "react";

import { navigate } from "gatsby";
import { Button, Form, FormGroup, Input } from "reactstrap";
import { StringParam, useQueryParam } from "use-query-params";

import clsx from "clsx";
import HorizontalLoader from "~/components/Loaders/HorizontalLoader";
import authenticationService from "~/utils/api/v1/authenticationService";
import Auth from "~/utils/auth/auth";
import { cleanUrl } from "~/utils/validations/urls";

const avatarLogin = require(`../assets/branding/isotipo.png`).default;

const auth = new Auth();

const AuthenticatingOverlay = () => {
  return (
    <div className="absolute inset-0 bg-white bg-opacity-95 flex items-center justify-center z-50 rounded-2xl">
      <HorizontalLoader />
    </div>
  );
};

const Login = () => {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [authenticating, setAuthenticating] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [queryError, setQueryError] = useQueryParam("error", StringParam);
  const [redirect, setRedirect] = useQueryParam("redirect", StringParam);

  useEffect(() => {
    if (auth.isLoggedIn()) {
      navigate("/dashboard/");
    }
    scanForErrors();
  }, []);

  const scanForErrors = (): void => {
    switch (queryError) {
      case "NotLoggedIn":
        setError("Debes iniciar sesión");
        break;
    }
  };

  const enableToLogin = () => {
    return [(loginData["email"] || "").length > 0, (loginData["password"] || "").length > 0].every(Boolean);
  };

  const handleChange = (e: any): void => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const requestLogin = async () => {
    setError("");
    setAuthenticating(true);
    try {
      const authenticated = await authenticationService.login({
        ...loginData,
      });
      if (authenticated) {
        const userRoles = authenticated["https://api.saludando.cl/roles"];
        /* check if user is superadmin */
        if (!userRoles.includes("superadmin")) {
          setError("Este usuario no tiene permisos suficientes para esto");
          return;
        }

        if (!redirect) {
          navigate("/dashboard/");
        } else {
          navigate(cleanUrl(redirect));
        }

        setAuthenticating(false);
      }
    } catch (e: any) {
      setError("Uh oh, usuario o contraseña incorrectos!");
    } finally {
      setAuthenticating(false);
    }
  };

  return (
    <div className="w-full min-h-screen p-5 pb-24 bg-gray-200">
      <div className="mx-auto max-w-screen-xl flex">
        <div className="bg-white w-full max-w-[500px] min-w-[200px] rounded-2xl mx-auto mt-12 relative px-6 py-10 sm:px-20 sm:py-20">
          {authenticating && <AuthenticatingOverlay />}
          <div className="text-center flex flex-col items-center">
            <img
              src={avatarLogin}
              alt="examedi-isotipo"
              className="w-10 h-10 object-contain"
            />
            <h1 className="mt-5">Ingresa a tu cuenta</h1>
            <div className="mt-5">
              <p className="mb-2.5 text-red-500 text-sm leading-4">
                {error}
              </p>
            </div>
          </div>

          <Form
            autoComplete="new-password"
            onSubmit={(e) => {
              e.preventDefault();
              try {
                requestLogin();
              } catch (e) {
                console.log(e);
              }
            }}
          >
            <FormGroup>
              <Input
                type="email"
                autoComplete="new-password"
                placeholder="Email"
                value={loginData["email"]}
                name="email"
                onChange={handleChange}
                className="h-11 mb-4"
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="password"
                autoComplete="new-password"
                placeholder="Password"
                name="password"
                value={loginData["password"]}
                onChange={handleChange}
                className="h-11 mb-4"
              />
            </FormGroup>
            <Button
              type="submit"
              disabled={!enableToLogin()}
              className={clsx(
                "w-full flex flex-row items-center justify-center rounded font-bold mt-5 !bg-[#039BE5] border !border-[#039BE5] text-white hover:!bg-[#039BE5]/80",
                !enableToLogin() && "!bg-gray-300 opacity-50",
              )}
            >
              <div className="text-center">Login</div>
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
